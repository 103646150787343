<template>
  <div class="footer-wrap">
    <footer>
      <v-container>
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
          >
            <v-container>
              <v-row>
                <v-col
                  cols="auto"
                  align-self="center"
                  class="mr-auto d-lg-none"
                >
                  <!-- <v-img
                    :src="require('@/assets/logo/logo-image-light.png')"
                    width="40"
                  /> -->
                  Footer here
                </v-col>
                <v-col
                  cols="auto"
                  align-self="center"
                  class="mr-auto d-none d-lg-block"
                >
                  <!-- <v-img
                    :src="require('@/assets/logo/logo-text-light.png')"
                    width="280"
                  /> -->
                  Footer here
                </v-col>
                <v-col
                  cols="auto"
                  style="margin-top: 7px;"
                >
                  <v-row>
                    <template
                      v-for="(item, i) in menuItems"
                    >
                      <v-col
                        v-if="item.isVisibleInFooter"
                        :key="`footer-lmenu-${i}`"
                        cols="auto"
                      >
                        <router-link

                          :to="item.link"
                          class="body-2 footer-link"
                        >
                          {{ item.title[$i18n.locale] }}
                        </router-link>
                      </v-col>
                    </template>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            class="my-4"
          >
            <v-divider />
          </v-col>
        </v-row>
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
          >
            <v-container>
              <v-row>
                <v-col
                  cols="auto"
                  class="mr-auto body-2"
                  align-self="center"
                >
                  &copy; {{ new Date().getFullYear() }} LTS inc.
                </v-col>
                <v-col
                  cols="auto"
                  align-self="center"
                >
                  <!-- <a
                    href="https://www.lifluence.com/about"
                    target="_lifluence"
                    class="body-2 footer-link"
                  >
                    소개
                  </a>
                </v-col>
                <v-col
                  cols="auto"
                  align-self="center"
                >
                  <router-link
                    to="/terms/tos"
                    class="body-2 footer-link"
                  >
                    서비스 이용약관
                  </router-link>
                </v-col>
                <v-col
                  cols="auto"
                  align-self="center"
                >
                  <router-link
                    to="/terms/privacy"
                    class="body-2 footer-link"
                  >
                    개인정보보호처리방침
                  </router-link> -->
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </footer>
  </div>
</template>

<script>
import menus from './menus'

export default {
  data() {
    return {
      menuItems: menus
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-wrap {
  background: #F4F6F7;
  padding-top: 80px;
  padding-bottom: 80px;
}
a.footer-link,
a.footer-link:visited {
  color: #343637;
  text-decoration: none;
}
a.footer-link:hover,
a.footer-link:focus {
  color: #141617;
  font-weight: bolder;
  text-decoration: underline;
}
</style>
